export function maskFone(value: string) {
  value = value.replace(/\D/g, '')
  value = value.replace(/(\d{2})(\d)/, '($1) $2')
  value = value.replace(/(\d)(\d{4})$/, '$1-$2')

  return value
}

export const ufList = [
  'Selecione',
  'AC',
  'AL',
  'AP',
  'AM',
  'BA',
  'CE',
  'DF',
  'ES',
  'GO',
  'MA',
  'MS',
  'MT',
  'MG',
  'PA',
  'PB',
  'PR',
  'PE',
  'PI',
  'RJ',
  'RN',
  'RS',
  'RO',
  'RR',
  'SC',
  'SP',
  'SE',
  'TO',
]

export function maskCNPJ(valorDoTextBox: string) {
  if (valorDoTextBox.length <= 18) {
    // Coloca ponto entre o segundo e o terceiro dígitos
    valorDoTextBox = valorDoTextBox.replace(/^(\d{2})(\d)/, '$1.$2')

    // Coloca ponto entre o quinto e o sexto dígitos
    valorDoTextBox = valorDoTextBox.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')

    // Coloca uma barra entre o oitavo e o nono dígitos
    valorDoTextBox = valorDoTextBox.replace(/\.(\d{3})(\d)/, '.$1/$2')

    // Coloca um hífen depois do bloco de quatro dígitos
    valorDoTextBox = valorDoTextBox.replace(/(\d{4})(\d)/, '$1-$2')
  }

  return valorDoTextBox
}

// Add "-" to numeric BRA postal code
export function maskPostalCode(postalCode: string | undefined) {
  if (!postalCode) {
    return null
  }

  return postalCode.replace(/(\d{5})(\d)/, '$1-$2')
}
